import React from 'react';
import MyAccountIndex from '../containers/index2';
import { MyaccountStoreProvider } from '../../../services';
import Layout from "../Layout"

const MyAccountIndexPage = () => {
    return (
        <Layout>
            <MyaccountStoreProvider>
                <MyAccountIndex />
            </MyaccountStoreProvider>
        </Layout>
    );
}

export default React.memo(MyAccountIndexPage);
