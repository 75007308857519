export default {
    "props": {
        "calendarContainerProps": {
            "elevation": 2,
        },
    },
    "sx": (theme) => ({
        "& .calendar-paper": {
            "padding": theme.spacing(2,0.5),
            "borderRadius": theme.moduleBorderRadius,
            "maxWidth": "400px",
            "margin": "auto",
            [theme.breakpoints.up('sm')]: {
                "padding": theme.spacing(4,5),
            },
            [theme.breakpoints.up('lg')]: {
                "marginTop": theme.spacing(5),
            }
        },
        "& .calendar-dayPicker": {
            "& .calendar-dayPicker-container": {
                "& .MuiButtonBase-root": {
                    "backgroundColor": theme.palette.grey.grey4,
                    "&.Mui-selected": {
                        "backgroundColor": theme.palette.primary.main,
                    },
                    "&.Mui-disabled": {
                        "backgroundColor": "transparent",
                        "color": "#ccc",
                    },
                },
            },
        },
    })
}
