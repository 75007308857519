import * as React from "react"
import Actions from "../actions"
import NoActions from "../progress/NoActions"
import Alert from "../alert"
import ActionsSkeleton from "../actions/actionsSkeleton"
import { ComponentWithPagination } from "../pagination"
import {
    useGetInboxItemsQuery,
    propertyApi,
} from "./../../../redux/services/property"
import store from "./../../../redux/store"
import { isAuthenticated } from "../../../services/store/utils"
import { collectResponseMessage } from "../../../redux/utils"

const PendingActionsInit = props => {
    const { noActionTitle, noActionMessage, noActionIcon, ...rest } = props
    const authUser = isAuthenticated()

    const {
        data: pendingActionData,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetInboxItemsQuery({}, { skip: !authUser })

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                "PendingActionCount",
                "PendingAction",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = (rest?.data && rest?.data.length) || pendingActionData || []

    if (isLoading && !data.length) {
        return <ActionsSkeleton sx={{ my: 2 }} />
    }

    if (isError) {
        const errorMsg = collectResponseMessage(data, error, isError, "")
        return <Alert type={`alert`} {...errorMsg} />
    }

    if (!isLoading && !data.length) {
        return (
            <NoActions
                title={
                    noActionTitle
                        ? noActionTitle
                        : "There are no pending actions"
                }
                // message={noActionMessage ? noActionMessage : "At the moment all the actions are closed..."}
                icon={noActionIcon ? noActionIcon : "noPendingActions"}
            />
        )
    }

    return (
        <ComponentWithPagination
            component={Actions}
            reloadDataFun={reloadDataFun}
            data={data}
        />
    )
}

const PendingActions = React.memo(props => {
    return <PendingActionsInit {...props} />
})

export default PendingActions
