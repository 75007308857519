import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Tabs from '../tabs';
import PendingActions from '../pendingActions';
import RecentActivities from '../recentActivities';
import { useAuthState } from "../../../services"

import PageTitle from '../pageTitle';
import SectionTitle from '../pages/my-property/common/sectionTitle';
import { DocumentBox, FilterBox } from '../StyleComp';
import { Box, Typography } from '@mui/material';
import myaccountData from '../../../../../static/data/myaccount.json';

const ActionComp = React.memo(() => {
    const tabs = [
        {
            "id": "tab1",
            "name": "Pending Actions",
            "content": <PendingActions />,
        },
        {
            "id": "tab2",
            "name": "Recent Activity",
            "content": <RecentActivities />,
        }
    ]
    return(
        <Tabs
            data={tabs}
            fullWidthTabs={true}
        />
    )
})

const MyAccountInbox = withSubtheme((props) => {
    // const {theme, children, className} = props;
    // const { services:authServices } = useAuthState();
    // const pageTitle = `Inbox`;

    // React.useEffect(() => {
    //     authServices.updateState({pageTitle:pageTitle, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Inbox`}
                tabsDescription={'inbox'}
                // tabsTitle={property?.display_address}
                actions={['contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* <ListItem
                                noRecord={noRecord}
                                data={offerData}
                                // image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            /> */}
                            {/* <ActionComp /> */}
                            <PendingActions />
                            {/* <RecentActivities /> */}
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
        </Container>
    )

    // return (
    //     <Container maxWidth="xl">
    //         <Grid container spacing={1}>
    //             <Grid item xs={12} lg={12}>
    //                 <PageTitle theme={theme} component="h1" variant="h3">{pageTitle}</PageTitle>
    //                 <ActionComp />
    //             </Grid>
    //         </Grid>
    //     </Container>
    // );
}, 'inbox')

export default MyAccountInbox;
