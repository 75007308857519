export default {
    "props": {
        "Sanmugam": "sanmugam kathirvel",
        publishStatuses: ["for sale", "sale agreed", "to let", "let agreed"],
        "header": {
            "sx": (theme) => ({
                "width": "calc(100% - 110px)",
                "& .propertyresult-address": {
                    // "fontWeight": theme.typography.fontWeightBold,
                },
            }),
        },
        "price": {
            "in": "header",
            "variant": "bodySmall",
        }
    },
    "sx": (theme) => ({
    }),
}
