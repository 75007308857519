import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, CalendarPicker } from '@mui/x-date-pickers';

import { withSubtheme } from '../../../StarberryComponentsMui';
// import moment from 'moment';

import Paper from '@mui/material/Paper';
import ComponentLayout from '../ComponentLayout';

import defaults from './defaults';

const InternalPickersSubtheme = withSubtheme(props => {
    const {
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        // defaultDate,
        className,
        calendarContainerProps,
        data
    } = props;

    const { date, getAvailableDate, /* setDate,*/ month, setMonth } = data

    const handleOnChange = (value) => {
        // setDate(value);
    }

    const handleMonthOnChange = (value) => {
        setMonth(value);
        // setDate(value);
    }

    const availableDates = (getAvailableDate() || [])
    const hasAvailableDates = true; // Object.keys(availableDates).length;

    const shouldDisableDateFun = (renderDate) => {
        if (hasAvailableDates) {
            const caleDate = new Date(renderDate);
            const caleDateMonth = caleDate.getMonth();
            const caleDateDay = caleDate.getDate();
            if (availableDates.hasOwnProperty(caleDateMonth)) {
                return !(availableDates[caleDateMonth].includes(caleDateDay))
            } else {
                return true;
            }
        }
    }

    const onYearChange = (value) =>  {
        setMonth(value);
    }


    return (
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Paper {...calendarContainerProps} classes={{root: "calendar-paper"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CalendarPicker
                        allowKeyboardControl={false}
                        date={date}
                        onChange={(newValue) => handleOnChange(newValue)}
                        shouldDisableDate={ hasAvailableDates && ((val) => shouldDisableDateFun(val))}
                        onMonthChange={(newMonth) => {handleMonthOnChange(newMonth)}}
                        defaultCalendarMonth={month}
                        onYearChange={(newDate) => {onYearChange(newDate)}}
                        classes={{
                            root: "calendar-dayPicker",
                            viewTransitionContainer: "calendar-dayPicker-container"
                        }}
                    />
                </LocalizationProvider>
            </Paper>
        </ComponentLayout>
    );
}, 'calendar', defaults)

const InternalPickers = React.memo((props) => {
    return (
        <InternalPickersSubtheme data={props} />
    );
})

export default InternalPickers
