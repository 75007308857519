import { display } from "@mui/system";

export default {
    props: {
        cardProps: {
            // "variant": "outlined",
            elevation: 0,
        },
    },
    sx: theme => ({
        marginBottom: theme.spacing(3.75),
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
        // [theme.breakpoints.up("lg")]: {
        //     marginBottom: theme.spacing(8),
        // },
        "&.welcome-card": {
            background: "transparent",
        },
        "& .welcomeCard-cardHeader": {
            padding: 0,
            "& .welcomeCard-avatar": {
                marginRight: theme.spacing(2.5),
                [theme.breakpoints.up("sm")]: {
                    marginRight: theme.spacing(3.75),
                },
                [theme.breakpoints.up("lg")]: {
                    display: "none",
                },
            },
            "& .welcomeCard-title": {
                fontSize: theme.h1,
                lineHeight: theme.h1LineHm,
                fontWeight: "normal",
                // textTransform: "capitalize",
                textTransform: "uppercase",
                color: theme.typography?.h1?.color || "initial",
                [theme.breakpoints.up("sm")]: {
                    fontSize: theme.typography?.h1?.fontSize || theme.h5,
                    fontWeight: theme.typography?.h1?.fontWeight || "normal",
                    fontFamily: theme.typography?.h1?.fontFamily || "initial",
                    lineHeight: theme.h3LineHm,
                    marginBottom: theme.spacing(1)
                },
            },
            "& .welcomeCard-subheader": {
                color: theme.palette.text.primary,
            },
        },
        "& .welcomeCard-cardHeaderCaption": {
            color: theme.palette.grey.grey2,
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        "& .welcomeCard-welcomeAvatar": {
            display: "none",
            // "margin": 0,
            // // "backgroundColor": "#ff0000",
            // "width": theme.spacing(10),
            // "height": theme.spacing(10),
        },
        "& .welcomeCard-welcomeAvatar-img": {
            // "margin": 0,
            // "backgroundColor": "#ff0000",
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        "& .action-text": {
            // display: "none",
            "& strong": {
                color: theme?.palette?.secondary?.main,
            },
        },
    }),
}
