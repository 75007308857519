import * as React from 'react';
import { get } from "lodash";
import { withSubtheme } from '../../../../StarberryComponentsMui';
import PropertyGrid from '../../../PropertyGrid';
import ComponentLayout from '../../ComponentLayout';
import defaults from './defaults';

const SingleProperty = withSubtheme(({theme, properties, title, moreLabel, moreLinkUrl, titleLinkMoreTooltipProps, ...props}) => {

    const propertyLink = theme.getProp('propertyLink');
    const status = props?.publishStatuses || [];
    const property = get(properties, '[0]', false);

    if (property && property?.status && status && propertyLink) {
        if (status.includes(property.status.toLowerCase())) {
            property.link = propertyLink(property);
            property.target = '_blank'
            properties = [property]
        }
    }

    return(
        <ComponentLayout
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <PropertyGrid
                theme={theme}
                properties={properties}
                {...props}
            />
        </ComponentLayout>
    )
}, 'singleProperty', defaults)

export default React.memo(SingleProperty)
