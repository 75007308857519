import * as React from "react"
import { withSubtheme } from "../../../../StarberryComponentsMui"
import Typography from "@mui/material/Typography"
import { LinkItem } from "../../helper/myaccountLink"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Avatar from "@mui/material/Avatar"
import { useAuthState } from "../../../../services"
import { MYACCOUNT_INBOX, MYACCOUNT_PROFILE } from "../../../../constants/urls"

import { connect } from "react-redux"

import defaults from "./defaults"
import { isAuthenticated } from "../../../../services/store/utils"
import { useGetPendingActionCountQuery } from "../../../../redux/services/property"

const PendingActions = ({ pendingItemsCount }) =>
    pendingItemsCount === 0 ? (
        <Typography className="action-text">
            You have no pending actions
            {/* <br />
        <LinkItem to={MYACCOUNT_PROFILE} variant="caption" classes={{root: "welcomeCard-cardHeaderCaption"}}>Notification Settings</LinkItem> */}
        </Typography>
    ) : (
        <Typography className="action-text">
            You have{" "}
            <LinkItem to={MYACCOUNT_INBOX}>
                <strong>{pendingItemsCount} Pending Actions</strong>
            </LinkItem>
            {/* <br />
        <LinkItem to={MYACCOUNT_PROFILE} variant="caption" classes={{root: "welcomeCard-cardHeaderCaption"}}>Notification Settings</LinkItem> */}
        </Typography>
    )

const Welcome = withSubtheme(
    props => {
        const { className, cardProps, theme } = props
        const { state } = useAuthState()
        // Fetch user data from context
        // const user = state?.user || {};

        const authUser = isAuthenticated()
        const { data, error, isError, isLoading, isFetching } =
            useGetPendingActionCountQuery({}, { skip: !authUser })

        const pendingItemsCount = data?.count || 0

        // Fetch user data from redux
        const user = props?.user || {}

        let displayName = `Welcome back, ${user.name}`
        let avatarClass = `welcomeCard-welcomeAvatar`
        if (!!user.picture) {
            displayName = `Welcome back, ${user.name}`
            avatarClass = `welcomeCard-welcomeAvatar-img`
        }

        return (
            <Card classes={{ root: `welcome-card ${className}` }} {...cardProps}>
                <CardHeader
                    avatar={
                        <>
                            {!!user.picture && (
                                <Avatar
                                    aria-label={user.name}
                                    classes={{ root: avatarClass }}
                                    alt={user.name}
                                    src={user.picture}
                                    sizes=""
                                />
                            )}
                        </>
                    }
                    title={displayName}
                    titleTypographyProps={{ variant: "h1" }}
                    subheaderTypographyProps={{ variant: "bodyLarge" }}
                    subheader={
                        <PendingActions pendingItemsCount={pendingItemsCount} />
                    }
                    classes={{
                        root: "welcomeCard-cardHeader",
                        avatar: `${avatarClass}`,
                        title: "welcomeCard-title",
                        subheader: "welcomeCard-subheader",
                    }}
                />
            </Card>
        )
    },
    "welcomeCard",
    defaults
)

const mapStateToProps = ({ profile }) => ({
    user: profile.user,
})

const WelcomeComponent = connect(mapStateToProps)(Welcome)

export default React.memo(WelcomeComponent)
